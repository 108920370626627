@use "sass:map";

$checked-color: #ebe5ea;

.checkbox-card {
  $self: &;

  transition: $transition-base;

  &:focus {
    border-color: $primary-08 !important;

    input {
      background-color: $surface-default;
    }

    svg {
      path {
        stroke: $indigo-2;
      }
    }
  }

  &:hover {
    background-color: $white-01;
    border-color: $indigo-2 !important;

    input {
      background-color: $surface-default;
    }

    svg {
      path {
        stroke: $indigo-2;
      }
    }
  }

  &--checked {
    background-color: $primary-01;
    border-color: $indigo-2 !important;

    svg {
      path {
        stroke: $indigo-2;
      }
    }
  }

  &--invalid {
    border-color: $red-1 !important;

    &:hover {
      border-color: $red-1 !important;
    }
  }
}
