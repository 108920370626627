// stylelint-disable function-disallowed-list
%offcanvas-css-vars {
  // scss-docs-start offcanvas-css-vars
  --#{$prefix}offcanvas-width: #{$offcanvas-horizontal-width};
  --#{$prefix}offcanvas-height: #{$offcanvas-vertical-height};
  --#{$prefix}offcanvas-padding-x: #{$offcanvas-padding-x};
  --#{$prefix}offcanvas-padding-y: #{$offcanvas-padding-y};
  --#{$prefix}offcanvas-color: #{$offcanvas-color};
  --#{$prefix}offcanvas-bg: #{$offcanvas-bg-color};
  --#{$prefix}offcanvas-border-width: #{$offcanvas-border-width};
  --#{$prefix}offcanvas-border-color: #{$offcanvas-border-color};
  --#{$prefix}offcanvas-box-shadow: #{$offcanvas-box-shadow};

  // scss-docs-end offcanvas-css-vars
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);

  .offcanvas#{$infix} {
    @extend %offcanvas-css-vars;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);

  .offcanvas#{$infix} {
    @include media-breakpoint-down($next) {
      position: fixed;
      bottom: 0;
      z-index: $zindex-offcanvas;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: var(--#{$prefix}offcanvas-color);
      visibility: hidden;
      background-color: var(--#{$prefix}offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      @include box-shadow(var(--#{$prefix}offcanvas-box-shadow));
      @include transition(transform $offcanvas-transition-duration ease-in-out);

      &.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--#{$prefix}offcanvas-width);
        border-right: var(--#{$prefix}offcanvas-border-width) solid var(--#{$prefix}offcanvas-border-color);
        transform: translateX(-100%);
      }

      &.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--#{$prefix}offcanvas-width);
        border-left: var(--#{$prefix}offcanvas-border-width) solid var(--#{$prefix}offcanvas-border-color);
        transform: translateX(100%);
      }

      &.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--#{$prefix}offcanvas-height);
        max-height: 100%;
        border-bottom: var(--#{$prefix}offcanvas-border-width) solid var(--#{$prefix}offcanvas-border-color);
        transform: translateY(-100%);
      }

      &.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--#{$prefix}offcanvas-height);
        max-height: 100%;
        border-top: var(--#{$prefix}offcanvas-border-width) solid var(--#{$prefix}offcanvas-border-color);
        border-radius: $offcanvas-bottom-border-radius $offcanvas-bottom-border-radius 0 0;
        transform: translateY(100%);

        .offcanvas-body {
          padding-top: 0;
        }
      }

      &.showing,
      &.show:not(.hiding) {
        transform: none;
      }

      &.showing,
      &.hiding,
      &.show {
        visibility: visible;
      }
    }

    @if not($infix == "") {
      @include media-breakpoint-up($next) {
        --#{$prefix}offcanvas-height: auto;
        --#{$prefix}offcanvas-border-width: 0;

        background-color: transparent !important; // stylelint-disable-line declaration-no-important

        .offcanvas-header {
          display: none;
        }

        .offcanvas-body {
          display: flex;
          flex-grow: 0;
          padding: 0;
          overflow-y: visible;

          // Reset `background-color` in case `.bg-*` classes are used in offcanvas
          background-color: transparent !important; // stylelint-disable-line declaration-no-important
        }
      }
    }
  }
}

.offcanvas.offcanvas-dialog {
  .offcanvas-body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  @include media-breakpoint-up(md) {
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    right: 0;
    max-width: $offcanvas-dialog-width-md;
    width: 100%;
    border: var(--#{$prefix}offcanvas-border-width) solid var(--#{$prefix}offcanvas-border-color);
    border-radius: $offcanvas-dialog-border-radius !important;
    height: fit-content;

    &:not(.show) {
      transition: none;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: $offcanvas-dialog-width-lg;
  }

  @include media-breakpoint-up(xl) {
    max-width: $offcanvas-dialog-width-xl;
  }
}

.offcanvas-backdrop {
  @include overlay-backdrop(
    $zindex-offcanvas-backdrop,
    $offcanvas-backdrop-bg,
    $offcanvas-backdrop-opacity
  );
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--#{$prefix}offcanvas-padding-y) $offcanvas-padding-bottom var(--#{$prefix}offcanvas-padding-x);

  .btn-close {
    color: $primary-06;
    width: 14px;
    height: 14px;
    opacity: 1;

    &:active {
      outline: none;
    }
  }
}

.offcanvas-title {
  @extend .typeface-body-small;

  margin-bottom: 0;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--#{$prefix}offcanvas-padding-y) var(--#{$prefix}offcanvas-padding-x);
  overflow-y: auto;
}
