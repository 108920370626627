@use "sass:map";

// Define alert colors, border radius, and padding.

// limiting the amount of possible states to match design system
$alert-states: ("info", "warning", "error", "success");

// scss-docs-start alert-variables
$alert-padding-y: $spacer * 1.5;
$alert-padding-x: $spacer * 1.5;
$alert-margin-bottom: 0;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-normal;
$alert-border-width: $border-width !default;
$alert-bg-scale: -80% !default;
$alert-border-scale: -70% !default;
$alert-color-scale: 40% !default;

// 3x covers width of x plus default padding on either side
$alert-dismissible-padding-r: $alert-padding-x * 3 !default;

// alert colors
$alert-border-color: (
  "info": $subtle,
  "warning": map.get($purple-rain-colors, "notification-alert-bold"),
  "error": map.get($purple-rain-colors, "notification-error-bold"),
  "success": map.get($purple-rain-colors, "notification-success-bold"),
);
$alert-btn-close-color: (
  "info": $primary-06,
  "warning": map.get($purple-rain-colors, "notification-alert-bold"),
  "error": map.get($purple-rain-colors, "notification-error-bold"),
  "success": map.get($purple-rain-colors, "notification-success-bold"),
);

// alert banner styles
$alert-banner-bg: (
  "info": $primary-05,
  "warning": map.get($purple-rain-colors, "notification-warning-bold"),
  "error": map.get($purple-rain-colors, "notification-error-bold"),
  "success": map.get($purple-rain-colors, "notification-success-bold"),
);
$alert-banner-color: map.get(
  $purple-rain-colors,
  "typography-dark-background-high-emphasis"
);
$alert-banner-border: none;
$alert-banner-border-radius: 0;

// scss-docs-end alert-variables
