//
// Include our web fonts
//
@font-face {
  font-family: Lora;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Lora/Bold.woff2") format("woff2");
}
@font-face {
  font-family: Lora;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Lora/Medium.woff2") format("woff2");
}
@font-face {
  font-family: Lora;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Lora/Regular.woff2") format("woff2");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Lato/Bold.woff") format("woff");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Lato/Medium.woff") format("woff");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Lato/Regular.woff") format("woff");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Lato/Light.woff2") format("woff2");
}
